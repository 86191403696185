import React, { useState, useEffect } from 'react'
import StarRating from './star-rating'
import Carousel from './carousel'
import scrollTo from 'gatsby-plugin-smoothscroll'

/**
 * Fetch and display reviews from stamped.io
 */
const ReviewStars = ({
  productId = '',
  title = '',
  preTitle = '',
  featuredOnly,
  compact = false,
  showTotals = false,
  ...props
}) => {
  const [reviewsData, setReviewsData] = useState(undefined)
  const [reviewsTotal, setReviewsTotal] = useState(undefined)
  const storeUrl = 'maja.no'
  const apiKey = 'pubkey-9ZMnaBr7CFv30Q9S6QKTM4mmH1437y'

  useEffect(() => {
    window.fetch(`https://stamped.io/api/widget/reviews?productId=${productId}&productType&email&isWithPhotos&minRating&take&dateFrom&dateTo&sortReviews=featured&storeUrl=${storeUrl}&apiKey=${apiKey}`)
      .then(response => response.status !== 200 ? null : response)
      .then(response => response.json())
      .then(resultData => {
        if (featuredOnly && resultData.data) {
          resultData.data = resultData.data.filter(review => review.featured)
        }

        setReviewsData(resultData)
      })
      .catch(error => {
        // do nothing.
      })

    window.fetch(`https://stamped.io/api/widget/reviews?productId=${productId}&productType&email&isWithPhotos&minRating&take&dateFrom&dateTo&sortReviews=featured&storeUrl=${storeUrl}&apiKey=${apiKey}&type=site-badge`)
      .then(response => response.status !== 200 ? null : response)
      .then(response => response.json())
      .then(resultData => {
        setReviewsTotal(resultData)
      })
      .catch(error => {
        // do nothing.
      })
  }, [])

  return (
    <>
      {reviewsData && reviewsData.data.length && (
        <div {...props}>
          {showTotals && reviewsTotal && (
            <a href="#anmeldelser" onClick={e => { e.preventDefault(); scrollTo('#anmeldelser') }} className='no-underline text-xs text-center mb-2 flex justify-start'>
              <StarRating score={reviewsTotal.rating} />
              {reviewsTotal.rating}/5 basert på {reviewsTotal.total} anmeldelser
            </a>
          )}
        </div>
      )}
    </>
  )
}

export default ReviewStars
